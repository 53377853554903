import styles from './sellAndSold.module.scss';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react'
import secondaryMarket from '@/store/secondaryMarket';
import primaryMarket from '@/store/primaryMarket';
import appState from '@/store/appState';
import { Checkbox, Dialog, Popup, Toast } from 'antd-mobile';
import moment from 'moment';

enum TransferState {
  transfer_success = '赠送成功',
  receive_success = '转入成功',
}

const SellAndSold: React.FC = () => {
  const location = useLocation()
  const [type, setType] = useState<'sell' | 'sold' | 'transfer'>(new URLSearchParams(location.search).get('type') || 'sell');
  const navigate = useNavigate();
  const { goodsMySalesList, ordersSaleList, goodsModify, goodsUnshelve } = secondaryMarket
  const [itemInfo, setItemInfo] = useState<any>({})
  const [visible, setVisible] = useState(false)
  const [dialogVisible, setDialogVisible] = useState(false)
  const [price, setPrice] = useState('');
  const { commonData } = appState
  const { nftOrderTransferQuery, nftOrderTransferQueryList } = primaryMarket

  const init = () => {
    if (type === 'sell') {
      secondaryMarket.goodsMySales()
    }
    if (type === 'sold') {
      secondaryMarket.ordersSale()
    }
    if (type === 'transfer') {
      nftOrderTransferQuery({
        type: 'transfer_success',
        limit: 1000
      })
    }
  }

  useEffect(() => {
    init()
  }, [type])


  const goodsModifyHandle = () => {
    goodsModify({
      price: Number(price) * 100,
      goods_id: itemInfo.goods_id
    }).then(({ data }) => {
      if (data.code === 0) {
        init()
        setVisible(false)
        setTimeout(() => {
          Toast.show({ icon: 'success', content: '修改成功' })
        }, 100);
      }
    })
  }



  const goodsUnshelveHandle = () => {
    goodsUnshelve({
      goods_id: itemInfo.goods_id
    }).then(({ data }) => {
      if (data.code === 0) {
        init()
        setDialogVisible(false)
        setTimeout(() => {
          Toast.show('取消成功')
        }, 100);
      }
    })
  }


  const chargesHandle = () => {
    if (Number(price) && Number(price) < 20) {
      return (Number(price) - 1).toFixed(2)
    }
    return (Number(price) * 0.95).toFixed(2)
  }




  return (
    <div id={styles['SellAndSold']}>
      <div className={styles['SellAndSold-title']}>
        <img onClick={() => navigate(-1)} src={require('@/assets/imgs/SellAndSold-line.svg').default} alt="" />
        <div className={styles['SellAndSold-title-right']}>
          <div className={`${styles['SellAndSold-title-right-sell']} ${type === 'sell' ? styles['switch'] : ''}`} onClick={() => setType('sell')}>在售中</div>
          <div className={`${styles['SellAndSold-title-right-sold']} ${type === 'sold' ? styles['switch'] : ''}`} onClick={() => setType('sold')}>已售出</div>
          <div className={`${styles['SellAndSold-title-right-sold']} ${type === 'transfer' ? styles['switch'] : ''}`} onClick={() => setType('transfer')}>转赠</div>
        </div>
      </div>
      {type === 'sell' && (goodsMySalesList.length > 0 ? goodsMySalesList.map((x, i) => (
        <div className={styles['SellAndSold-box1']} key={i} onClick={() => { setItemInfo(x); setPrice(String(Number(x.price) / 100)) }}>
          <div className={styles['SellAndSold-box1-top']}>
            <div className={styles['SellAndSold-box1-top-left']}>
              <img src={x.thumb && x.thumb[x.thumb.length - 1]} alt="" />
              <h3 className={`${styles['SellAndSold-box1-top-left-h3']}`}>{x.title}</h3>
            </div>
            <div className={styles['SellAndSold-box1-top-right']}>
              <div><span className={styles['SellAndSold-box1-top-right-span']}>¥&nbsp;</span>{(x.price) / 100}</div>
            </div>
          </div>
          <div className={`${styles['SellAndSold-box1-bottom']}`}>
            <div className={`${styles['SellAndSold-box1-bottom-left']}`}><span className={styles['SellAndSold-box1-bottom-left-span']}>¥&nbsp;</span>{(x.price) / 100}</div>
            <div className={styles['SellAndSold-box1-bottom-right']}>
              <div className={`${styles['SellAndSold-box1-bottom-right-price']}`} onClick={() => setVisible(true)}>
                <img className={styles['SellAndSold-box1-bottom-right-price-img']} src={require('@/assets/imgs/SellAndSold-edit.svg').default} alt="" />
                <div>改价</div>
              </div>
              <div className={`${styles['SellAndSold-box1-bottom-right-end']}`} onClick={() => setDialogVisible(true)}>
                <img className={styles['SellAndSold-box1-bottom-right-end-img']} src={require('@/assets/imgs/SellAndSold-bin.svg').default} alt="" />
                <div className={styles['SellAndSold-box1-bottom-right-end-text']}>下架</div>
              </div>
            </div>
          </div>
        </div>
      )) : (
        <div className='noData' style={{ top: 300 }}>
          <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
          <span className='noData-text'>暂无数据</span>
        </div>
      ))}



      {type === 'sold' && (ordersSaleList.length > 0 ? ordersSaleList.map((item, i) => (
        <div className={styles['SellAndSold-box1']} key={i}>
          <div className={styles['SellAndSold-box1-top']}>
            <div className={styles['SellAndSold-box1-top-left']}>
              <img src={item.goods?.thumb[item.goods?.thumb.length - 1]} alt="" />
              <h3 className={`${styles['SellAndSold-box1-top-left-h3']}`}>{item.type === 'match_bid' && '求购出售：'}{item.goods?.title}</h3>
            </div>
            <div className={styles['SellAndSold-box1-top-right']}>
              <div><span className={styles['SellAndSold-box1-top-right-span']}>¥&nbsp;</span>{(item.price) / 100}</div>
              <div className={`${styles['SellAndSold-box1-top-right-num']}`}>×&nbsp;{item.amount || 1}</div>
            </div>
          </div>

          <div className={`${styles['SellAndSold-box1-bottom2']}`}>
            <div className={styles['SellAndSold-box1-bottom2-left']}>
              <div>付款时间</div>
              <div>订单编号</div>
            </div>
            <div className={styles['SellAndSold-box1-bottom2-right']}>
              <div>{item.pay_time ? moment(item.pay_time).format('YYYY.MM.DD HH:mm') : '--'}</div>
              <a className={styles['SellAndSold-box1-bottom2-right-num']}>{item.order_id}</a>
            </div>
          </div>
        </div>
      )) : (
        <div className='noData' style={{ top: 300 }}>
          <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
          <span className='noData-text'>暂无数据</span>
        </div>
      ))}

      {type === 'transfer' && (nftOrderTransferQueryList.length > 0 ? nftOrderTransferQueryList.map((item, i) => (
        <div className={styles['SellAndSold-box1']} key={i}>
          <div className={styles['SellAndSold-box1-top']}>
            <div className={styles['SellAndSold-box1-top-left']}>
              <img src={item.thumb[item.thumb.length - 1]} alt="" />
              <h3 className={`${styles['SellAndSold-box1-top-left-h3']}`}>
                {item.title}
                <div className={styles["GiveNft-top-right-center"]}>
                  <img src={require('@/assets/imgs/nftDetail-small.svg').default} alt="" />
                  <span className={styles["GiveNft-top-right-center-text"]}>#{item?.shard_id}/{item?.amount}</span>
                </div>
                <span><span style={{ fontSize: 10 }}>¥</span> {item.price / 100}</span>
              </h3>
            </div>
            <div className={styles['transfer_state']}>
              {TransferState[item.state as 'transfer_success']}
            </div>
          </div>

          <div className={`${styles['SellAndSold-box1-bottom2']}`}>
            <div className={styles['SellAndSold-box1-bottom2-left']}>
              <div>付款时间</div>
              <div>订单编号</div>
            </div>
            <div className={styles['SellAndSold-box1-bottom2-right']}>
              <div>{item.pay_time ? moment(item.pay_time).format('YYYY.MM.DD HH:mm') : '--'}</div>
              <a className={styles['SellAndSold-box1-bottom2-right-num']}>{item.order_no}</a>
            </div>
          </div>
        </div>
      )) : (
        <div className='noData' style={{ top: 300 }}>
          <img className='noData-img' src={require('@/assets/imgs/synthesis.png')} />
          <span className='noData-text'>暂无数据</span>
        </div>
      ))}


      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '78vh',
        }}
        getContainer={null}
      >
        <div className={styles['Popup-top']}>
          <div className={styles['Popup-top-title']}>
            <img className={styles['Popup-top-img']} src={itemInfo?.thumb && itemInfo?.thumb[itemInfo?.thumb?.length - 1]} alt="" />
            <div className={styles['Popup-top-right']}>
              <div className={styles['Popup-top-right-name']}>{itemInfo?.title}</div>
              <div className={styles['Popup-top-right-two']}>
                <img className={styles['Popup-top-right-img']} src={require('@/assets/imgs/onChainInformation-yes.svg').default} alt="" />
                <div className={styles['Popup-top-right-number']}>#{itemInfo?.sid}/{itemInfo?.publish_amount}</div>
              </div>
            </div>
          </div>
          <div className={styles['Popup-top-money']}>
            <div className={styles['Popup-top-money-tet']}>¥</div>
            <input className={styles['Popup-top-money-inp']} type="number" value={price} onChange={(e) => {
              if (e.target.value.match(/^\d+$/) || e.target.value === '') {
                setPrice(e.target.value)
              }
            }} />
          </div>
          <div className={styles['Popup-top-money-service']}>服务费5%,&nbsp;<span className={styles['Popup-top-money-service-span']}>预计到手&nbsp;{chargesHandle()}</span></div>
          <div className={styles['Popup-top-money-service']}>Wyper燃料：本次寄售改价需要消耗&nbsp;<span className={styles['Popup-top-money-service-span']}>{itemInfo?.bonus_modify} {itemInfo?.bonus_type}</span></div>
        </div>

        <div className={styles['Popup-top-center']}>
          <h4 className={styles['Popup-h4']}>收款方式</h4>
          <div className={styles['Popup-wallet-sd']}>
            <div className={styles['Popup-wallet-sd-left']}>
              <img className={styles['Popup-wallet-sd-left-img']} src={require('@/assets/imgs/onChainInformation-money1.svg').default} alt="" />
              <div className={styles['Popup-wallet-sd-left-text']}>{commonData?.wallet?.bind ? `杉德钱包 (余额 ${((commonData?.wallet.balance) / 100).toFixed(2)})` : '杉德钱包 (未开通)'}</div>
            </div>
            <div className={styles['Popup-wallet-sd-left-checkbox']}>
              {commonData?.wallet?.bind ?
                <Checkbox checked={itemInfo?.pay_channels?.some((x: string) => x === 'sandpay')} disabled />
                :
                (
                  <div className={styles['Popup-wallet-hf-right']} onClick={() => window.location.href = commonData?.wallet?.url}>
                    <div className={styles['Popup-wallet-hf-right-add']}>立即开通</div>
                    <img className={styles['Popup-wallet-hf-left-img']} src={require('@/assets/imgs/onChainInformation-right-fill.svg').default} alt="" />
                  </div>
                )}
            </div>
          </div>

          <div className={styles['Popup-wallet-hf']}>
            <div className={styles['Popup-wallet-hf-left']}>
              <img className={styles['Popup-wallet-hf-left-img']} src={require('@/assets/imgs/onChainInformation-money2.svg').default} alt="" />
              <div className={styles['Popup-wallet-hf-left-text']}>{commonData?.wallet2?.bind ? `汇付钱包 (余额 ${((commonData?.wallet2.balance) / 100).toFixed(2)})` : '汇付钱包 (未开通)'}</div>
            </div>
            {commonData?.wallet2?.bind ?
              <Checkbox checked={itemInfo?.pay_channels?.some((x: string) => x === 'huifupay')} disabled />
              :
              (
                <div className={styles['Popup-wallet-hf-right']} onClick={() => window.location.href = commonData?.wallet2?.url}>
                  <div className={styles['Popup-wallet-hf-right-add']}>立即开通</div>
                  <img className={styles['Popup-wallet-hf-left-img']} src={require('@/assets/imgs/onChainInformation-right-fill.svg').default} alt="" />
                </div>
              )}
          </div>
        </div>

        <div className={styles['Popup-top-foot']}>
          <h3 className={styles['Popup-top-foot-h3']}>寄售须知</h3>
          <p className={styles['Popup-top-foot-p']}>1.寄售收入在扣除技术服务费后，将自动转入您的钱包余额；</p>
          <p className={styles['Popup-top-foot-p']}>2.寄售收入的增值部分，请您主动申报个人所得税；</p>
          <p className={styles['Popup-top-foot-p']}>3.每次寄售上架、下架、修改价格都将消耗一定Wyper燃料；</p>
          <p className={styles['Popup-top-foot-p']}>4.Wyper燃料不足将无法完成寄售相关功能，详情参考相关公告；</p>
          <p className={styles['Popup-top-foot-p']}>5.数字版权品仅用于欣赏和收藏，而不是用于投资、投机或炒作，请谨慎参与!</p>
        </div>

        <div className={styles['Popup-top-btn-box']} onClick={goodsModifyHandle}>
          <div className={styles['Popup-top-btn']}>修改价格</div>
        </div>
      </Popup>

      <Dialog
        visible={dialogVisible}
        title={'取消寄售'}
        content={<span style={{ fontSize: '14px', textAlign: 'center', color: '#999' }}>取消寄售后，您的藏品将被解锁且不会展示在市场列表中，用户无法继续查看您的藏品信息。请确认后继续操作。</span>}
        actions={[
          [
            {
              key: 'cancel',
              text: '取消',
            },
            {
              key: 'confirm',
              text: '确定',
              style: { color: 'red' }
            },
          ]
        ]}
        onAction={(action) => {
          switch (action.key) {
            case 'cancel':
              return setDialogVisible(false)
            case 'confirm':
              return goodsUnshelveHandle()
          }
        }}
      />

      {/* <img className={styles['SellAndSold-btn']} src={require('@/assets/imgs/SellAndSold-add.svg').default} alt="" /> */}
    </div>
  )
}

export default observer(SellAndSold);