import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { observer } from 'mobx-react'
import copy from 'copy-to-clipboard'
import { Popup, Toast, Checkbox } from 'antd-mobile'
import moment from 'moment'
import primaryMarket from '@/store/primaryMarket'
import appState from '@/store/appState'
import DealPassword from '@/component/dealPassword/dealPassword'
import secondaryMarket from '@/store/secondaryMarket'
import { RightOutline } from 'antd-mobile-icons';

import Header from '../../component/header/header'

import './assetsDetail.scss'

enum source {
  transfer = '首发价',
  box = '盲盒开出',
  asset = '首发价',
  dev = '空投',
  composite = '合成铸造'
}

const AssetsDetail: React.FC = () => {
  const navigate = useNavigate()
  const { assetsDetail } = primaryMarket
  const [visible, setVisible] = useState(false)
  const location = useLocation()
  const data = JSON.parse(location.state)
  const { commonData } = appState
  const [price, setPrice] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false)

  const init = () => {
    if (data) {
      primaryMarket.getAssetDetail({ asset_id: data.asset_id, shard_id: data.shard_id }).then(({ data }) => {
        const long_desc_div = document.querySelector('.long_desc') as Element
        const author_desc_div = document.querySelector('.author_desc') as Element
        const remark_div = document.querySelector('.remark') as Element
        long_desc_div.innerHTML = data.long_desc
        author_desc_div.innerHTML = data.author_desc
        remark_div.innerHTML = data.remark
      })

      secondaryMarket.getOrdersTransactionList({
        aid: data.asset_id,
        sid: data.shard_id
      })
    }
  }

  useEffect(() => {
    init()
  }, [])









  /**
    * 选择收款方式
    * 
    * @param sandWallet 衫德钱包
    * 
    * @param huifuWallet 汇付钱包
    * 
    */
  const [sandWallet, setSandWallet] = useState(commonData?.wallet?.bind)
  const [huifuWallet, setHuifuWallet] = useState(commonData?.wallet2?.bind)
  const [payChannels, setPayChannels] = useState<Array<'sandpay' | 'huifupay'>>([])
  useEffect(() => {
    if (sandWallet && huifuWallet) {
      setPayChannels(['sandpay', 'huifupay'])
    }
    if (sandWallet && !huifuWallet) {
      setPayChannels(['sandpay'])
    }
    if (!sandWallet && huifuWallet) {
      setPayChannels(['huifupay'])
    }
    if (!sandWallet && !huifuWallet) {
      setPayChannels([])
    }
  }, [sandWallet, huifuWallet])





  const goodsShelveHandle = (password: string) => {
    secondaryMarket.goodsShelve({
      price: Number(price) * 100,
      sku: {
        aid: assetsDetail?.asset_id,
        sid: assetsDetail?.shard_id
      },
      pay_channels: payChannels
    }, {
      password
    }).then(({ data }) => {
      if (data.code === 0) {
        setTimeout(() => {
          navigate(-1)
        }, 1000);
        setVisible(false)
        init()
        setTimeout(() => {
          Toast.show({ icon: 'success', content: '寄售成功' })
        }, 100);
      }
    })
  }




  const chargesHandle = () => {
    if (Number(price) && Number(price) < 20) {
      return (Number(price) - 1).toFixed(2)
    }
    return (Number(price) * 0.95).toFixed(2)
  }



  return (
    <div id="assetsDetail">
      <div className="main">
        <Header text="藏品详情" />
        <img className="CommodityDetail-img" src={assetsDetail?.thumb && assetsDetail?.thumb[assetsDetail?.thumb?.length - 1]} />
        <div className='box1'>
          <img className='box1-img' src={require('../../assets/imgs/nftDetail-small.svg').default} />
          <span className='box1-text'>该作品拥有 Wyper 官方认证</span>
        </div>
        <div className='box2'>
          <span className='box2-text'>{assetsDetail.title}#{assetsDetail.shard_id}</span>
          {assetsDetail.grade && <img className='MyAssets-box2-item-grade' width={24} height={24} src={require(`@/assets/imgs/${assetsDetail.grade}.svg`)} alt="" />}
        </div>
        <div className='box3'>
          <div className='box3-item'>
            <span className='box3-item-text1'>发行量</span>
            <span className='box3-item-text2'>{assetsDetail.publish_amount}份</span>
          </div>
          <div className='box3-item'>
            <span className='box3-item-text1'>流通量</span>
            <span className='box3-item-text2'>{assetsDetail.circulate_amount}份</span>
          </div>
          <div className='box3-item'>
            <span className='box3-item-text1'>销毁量</span>
            <span className='box3-item-text2'>{assetsDetail.destroy_amount}份</span>
          </div>
        </div>
        <div className='box4'>
          <span className='box4-title'>链上信息</span>
          <div className='box4-data' onClick={() => { copy(assetsDetail.asset_address); Toast.show({ icon: 'success', content: '复制成功' }) }}>
            <span className='box4-data-text1'>藏品地址：</span>
            <span className='box4-data-text2'>{assetsDetail.asset_address}</span>
          </div>
          <div className='box4-data' onClick={() => { copy(assetsDetail.tx_id); Toast.show({ icon: 'success', content: '复制成功' }) }}>
            <span className='box4-data-text1'>交易哈希：</span>
            <span className='box4-data-text2'>{assetsDetail.tx_id}</span>
          </div>
          <div className='box4-data' onClick={() => { copy(assetsDetail.asset_id); Toast.show({ icon: 'success', content: '复制成功' }) }}>
            <span className='box4-data-text1'>藏品编号：</span>
            <span className='box4-data-text2'>{assetsDetail.asset_id}</span>
          </div>
          <div className='box4-data'>
            <span className='box4-data-text1'>转入时间：</span>
            <span className='box4-data-text2'>{moment(assetsDetail.granted_time).format('YYYY-MM-DD HH:mm')}</span>
          </div>
          <div className='box4-data'>
            <span className='box4-data-text1'>链上记录：</span>
            <img className='box4-data-img' onClick={() => window.location.href = `https://xuper.baidu.com/n/scan#/goods/${assetsDetail.asset_id}`} src={require('../../assets/imgs/baidu.png')} />
          </div>
        </div>
        {secondaryMarket.ordersTransactionList.length > 0 && (<div className='onChainInformation-record'>
          <div className='onChainInformation-record-top'>
            <h4>链上记录</h4>
            <div className='onChainInformation-record-right'>
              <div className='onChainInformation-record-right-text' onClick={() => navigate('/secondaryMarket/OnChainRecords', { state: { aid: assetsDetail?.asset_id, sid: assetsDetail?.shard_id } })}>查看更多</div>
              <RightOutline fontSize={10} />
            </div>
          </div>
          <div className='onChainInformation-record-over'>
            {secondaryMarket.ordersTransactionList.map((x, i) => (
              <div className='onChainInformation-record-box'>
                <div className='onChainInformation-record-box-left'>
                  <h3>{x.name}</h3>
                  <p className='onChainInformation-record-box-left-p'>{moment(x.time).format('YYYY.MM.DD HH:mm')}</p>
                </div>
                <div className='onChainInformation-record-box-right'>买入&nbsp;¥ {x.price}</div>
              </div>
            ))}
          </div>
        </div>)}
        <div className="box7">
          {assetsDetail.author_desc && <h2 className="title">艺术家介绍</h2>}
          <p className="desc author_desc" style={assetsDetail.author_desc ? {} : { display: 'none' }}></p>
          {assetsDetail.long_desc && <h2 className="title">作品描述</h2>}
          <p className="desc long_desc" style={assetsDetail.long_desc ? {} : { display: 'none' }}></p>
          {assetsDetail.remark && <h2 className="title">作品权益</h2>}
          <p className="desc remark" style={assetsDetail.remark ? {} : { display: 'none' }}></p>
          <h2 className="title">交易须知</h2>
          <p className="desc">
            <span>1.所有作品信息由创作者上传，购买前须仔细甄别并自行判断，平台不为创作者的承诺做担保，如果创作者末兑现其承诺，一切与平台无关。</span>
            <span>2.除“数字许可作品”以外，用户末经授权不得将持有的数字作品用于任何商业用途。</span>
            <span>3.平台坚决反对数字作品炒作，禁止用户进行任何形式的数字作品炒作及私下交易数字作品。</span>
            <span>4.数字作品一经交易不支持退换，不支持7天无理由退款，请谨慎购买。</span>
            <span>5.如因发布数字作品的用户或者作品原创作者侵权或发布危害国家利益、违反社会道德与公序良俗以及其他负面言论或行为或作品存在权利瑕疵或违法违规等原因导致作品被隐藏、下架或限制交易的，购买作品的用户应当自行与发布作品的创作者沟通解决。</span>
          </p>
        </div>
        <div className='CommodityDetail-footer'>
          <div className='CommodityDetail-footer-left'>
            <span className='CommodityDetail-footer-left-text1'>{source[assetsDetail.source as 'transfer']}</span>
            <span className='CommodityDetail-footer-left-text2'><span style={{ fontSize: 14 }}>¥</span> {assetsDetail.price}</span>
          </div>
          {assetsDetail.sale_code === 0 || assetsDetail.sale_code === 5003 ?
            (
              <div className='CommodityDetail-footer-btn btn' onClick={() => {
                if (assetsDetail.can_sale) {
                  setVisible(true)
                }
              }}>
                {assetsDetail.can_sale ? '发布寄售' : '寄售中...'}
              </div>
            )
            : assetsDetail.can_transfer ? (
              <div className='CommodityDetail-footer-btn btn' onClick={() => navigate('/giveNft', { state: JSON.stringify(assetsDetail) })}>
                礼赠朋友
              </div>
            ) :
              (
                <div className='CommodityDetail-footer-btn btn'>
                  不可流转
                </div>
              )}
        </div>
      </div>

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '40vh',
        }}
        getContainer={null}
      >
        <div className='Popup-top'>
          <div className='Popup-top-title'>
            <img className='Popup-top-img' src={assetsDetail?.thumb && assetsDetail?.thumb[assetsDetail?.thumb?.length - 1]} alt="" />
            <div className='Popup-top-right'>
              <div className='Popup-top-right-name'>{assetsDetail?.title}</div>
              <div className='Popup-top-right-two'>
                <img className='Popup-top-right-img' src={require('@/assets/imgs/onChainInformation-yes.svg').default} alt="" />
                <div className='Popup-top-right-number'>#{assetsDetail?.shard_id}/{assetsDetail?.amount}</div>
              </div>
            </div>
          </div>
          <div className='Popup-top-money'>
            <div className='Popup-top-money-tet'>¥</div>
            <input className='Popup-top-money-inp' type="number" value={price} onChange={(e) => {
              if (e.target.value.match(/^\d+$/) || e.target.value === '') {
                setPrice(e.target.value)
              }
            }} />
          </div>
          <div className='Popup-top-money-service'>服务费5%,&nbsp;<span className='Popup-top-money-service-span'>预计到手&nbsp;{chargesHandle()}</span></div>
          <div className='Popup-top-money-service'>Wyper燃料：本次寄售上架需要消耗&nbsp;<span className='Popup-top-money-service-span'>{assetsDetail?.bonus_shelve} {assetsDetail?.bonus_type}</span></div>
        </div>

        <div className='Popup-top-center'>
          <h4 className='Popup-h4'>收款方式</h4>
          <div className='Popup-wallet-sd'>
            <div className='Popup-wallet-sd-left'>
              <img className='Popup-wallet-sd-left-img' src={require('@/assets/imgs/onChainInformation-money1.svg').default} alt="" />
              <div className='Popup-wallet-sd-left-text'>{commonData?.wallet?.bind ? `杉德钱包 (余额 ${((commonData?.wallet.balance) / 100).toFixed(2)})` : '杉德钱包 (未开通)'}</div>
            </div>
            <div className='Popup-wallet-sd-left-checkbox'>
              {commonData?.wallet?.bind ?
                <Checkbox checked={sandWallet} onChange={() => setSandWallet((x: any) => !x)} />
                :
                (
                  <div className='Popup-wallet-hf-right' onClick={() => window.location.href = commonData?.wallet?.url}>
                    <div className='Popup-wallet-hf-right-add'>立即开通</div>
                    <img className='Popup-wallet-hf-left-img' src={require('@/assets/imgs/onChainInformation-right-fill.svg').default} alt="" />
                  </div>
                )}
            </div>
          </div>

          <div className='Popup-wallet-hf'>
            <div className='Popup-wallet-hf-left'>
              <img className='Popup-wallet-hf-left-img' src={require('@/assets/imgs/onChainInformation-money2.svg').default} alt="" />
              <div className='Popup-wallet-hf-left-text'>{commonData?.wallet2?.bind ? `汇付钱包 (余额 ${((commonData?.wallet2.balance) / 100).toFixed(2)})` : '汇付钱包 (未开通)'}</div>
            </div>
            {commonData?.wallet2?.bind ?
              <Checkbox checked={huifuWallet} onChange={() => setHuifuWallet((x: any) => !x)} />
              :
              (
                <div className='Popup-wallet-hf-right' onClick={() => window.location.href = commonData?.wallet2?.url}>
                  <div className='Popup-wallet-hf-right-add'>立即开通</div>
                  <img className='Popup-wallet-hf-left-img' src={require('@/assets/imgs/onChainInformation-right-fill.svg').default} alt="" />
                </div>
              )}
          </div>
        </div>

        <div className='Popup-top-foot'>
          <h3 className='Popup-top-foot-h3'>用户须知</h3>
          <p className='Popup-top-foot-p'>1.寄售收入在扣除技术服务费后，将自动转入您的钱包余额；</p>
          <p className='Popup-top-foot-p'>2.寄售收入的增值部分，请您主动申报个人所得税；</p>
          <p className='Popup-top-foot-p'>3.每次寄售上架、下架、修改价格都将消耗一定Wyper燃料；</p>
          <p className='Popup-top-foot-p'>4.Wyper燃料不足将无法完成寄售相关功能，详情参考相关公告；</p>
          <p className='Popup-top-foot-p'>5.数字版权品仅用于欣赏和收藏，而不是用于投资、投机或炒作，请谨慎参与!</p>
        </div>

        <div className='Popup-top-btn-box' onClick={() => {
          if (!commonData?.has_password) {
            return navigate('/password')
          }
          if (payChannels.length <= 0) {
            return Toast.show('至少选择一个收款方式')
          }
          if (price === '') {
            return Toast.show('请输入寄售价格')
          }
          setPasswordVisible(true)
        }}>
          <div className='Popup-top-btn btn'>发布寄售</div>
        </div>
      </Popup>

      <DealPassword
        visible={passwordVisible}
        onConfirm={goodsShelveHandle}
        onClose={() => setPasswordVisible(false)}
      />
    </div >
  )
}

export default observer(AssetsDetail)
