import axios from "axios";
import { Toast } from "antd-mobile";

const instance = axios.create({
  timeout: 1000 * 15,
  // baseURL: process.env.NODE_ENV === 'production' ? 'https://nftgame-test.wyper.art' : 'https://nftgame-test.wyper.art'
  baseURL: process.env.NODE_ENV === 'production' ? 'https://nftgame.wyper.art' : 'https://nftgame.wyper.art'
})

instance.interceptors.request.use(config => {
  if (config.url?.indexOf('/nft/order/queryOrder') !== 0) {
    Toast.show({
      icon: 'loading',
      content: '加载中...',
      duration: 0
    })
  }

  const commonData = JSON.parse(localStorage.getItem('commonData') || '{}')
  if (commonData.authorization) {
    config.headers['Authorization'] = commonData.authorization
  }

  return config
})

instance.interceptors.response.use(response => {
  Toast.clear()

  if (response?.data?.code && response?.data?.code !== 0) {
    switch (response?.data?.code) {
      case 41000:
        localStorage.clear();
        window.location.replace('/login/wyper');
        break;
      case 40005:
        if (window.location.pathname !== '/inviteCode' && window.location.pathname !== '/login') {
          window.location.replace('/inviteCode');
        }
        break;
    }
    Toast.show({ content: response?.data?.message })
  }

  return response
})

instance.defaults.timeout = 20000;
instance.defaults.headers.post.Accept = 'application/json'
instance.defaults.headers.post['Content-Type'] = 'application/json'

export default instance
